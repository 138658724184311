import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { ROUTE_URLS } from 'constants/index';

const Footer = () => {
	const { DEV_PORTAL } = ROUTE_URLS;
	const _now = new Date(Date.now());
	let location = useLocation();
	if (location.pathname.match('/app-creator')) return '';

	return (
		<section className="dhlsmuvcdc dhlsmuvcdc-xl">
			<div className="ykiyguxiww ykiyguxiww-xl">
				<div className="ncwkpamvyl ncwkpamvyl-xl">
					<nav className="menu menu-default">
						<div className="menu menu-horizontal">
							<ul className="nav">
								<li className="nav-item">
									<a href="https://www.paycor.com/" className="nav-link">
										Paycor.com
									</a>
								</li>
								<li className="nav-item">
									<NavLink to="/" className="nav-link">
										Home
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to={DEV_PORTAL.API_EXPLORE} className="nav-link">
										APIs
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to={DEV_PORTAL.GUIDES} className="nav-link">
										Guides
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink to={DEV_PORTAL.SUPPORT} className="nav-link nav-link-active">
										Support
									</NavLink>
								</li>
								<li className="nav-item">
									<a href="https://marketplace.paycor.com/" className="nav-link">
										Marketplace
									</a>
								</li>
								<li className="nav-item">
									<a href="http://partnerportal.paycor.com/" className="nav-link">
										Partner With Us
									</a>
								</li>
								<li className="nav-item">
									<NavLink to={DEV_PORTAL.TERMS_OF_USE} className="nav-link">
										Terms of Use
									</NavLink>
								</li>
							</ul>
						</div>
					</nav>
					<div className="block ProseMirror">
						<p className="text text-xl-align-center">
							&copy; {_now.getFullYear()} Paycor, Inc | <a href="https://www.paycor.com/privacy-policy">Privacy Policy </a>| 1-800-501-9462
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footer;
