import React, { lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { LoadingStatus } from '@paycor/design-system';
import { ROUTE_URLS } from 'constants/index';
import Home from 'features/Home';
import ScrollToTopRoute from 'components/CustomRoutes/ScrollToTopRoute';

const AppCreator = lazy(() => import('features/AppCreator'));
const ApiExplorer = lazy(() => import('features/Documentation/pages/api-explorer'));
const ApiTry = lazy(() => import('features/Documentation/pages/api-try'));
const EventNotification = lazy(() => import('features/Documentation/pages/event-notification'));
const JoinDeveloperPortal = lazy(() => import('features/AppCreator/pages/JoinDeveloperPortal'));
const Guides = lazy(() => import('features/Documentation/pages/Guides'));
const PageNotFound = lazy(() => import('components/PageNotFound'));
const Support = lazy(() => import('features/Documentation/pages/Support'));
const TermsOfUse = lazy(() => import('features/Documentation/pages/terms-of-use'));
const News = lazy(() => import('features/Documentation/pages/News'));

export default () => {
	const { DEV_PORTAL, APP_CREATOR, PAGE_NOT_FOUND } = ROUTE_URLS;

	return (
		<Suspense fallback={<LoadingStatus />}>
			<Switch>
				<ScrollToTopRoute path={DEV_PORTAL.SUPPORT} component={Support} />
				<ScrollToTopRoute path={DEV_PORTAL.TERMS_OF_USE} component={TermsOfUse} />
				<ScrollToTopRoute path={DEV_PORTAL.GUIDES} component={Guides} />
				<ScrollToTopRoute path={DEV_PORTAL.NEWS} component={News} />
				<ScrollToTopRoute path={DEV_PORTAL.EVENT_NOTIFICATION} component={EventNotification} />
				<ScrollToTopRoute path={DEV_PORTAL.API_EXPLORE} component={ApiExplorer} />
				<ScrollToTopRoute path={DEV_PORTAL.API_TRY} component={ApiTry} />
				<ScrollToTopRoute path={DEV_PORTAL.JOIN_DEVELOPER_PORTAL} component={JoinDeveloperPortal} />
				<ScrollToTopRoute path={APP_CREATOR.HOME} component={AppCreator} />
				<ScrollToTopRoute exact path={'/'} component={Home} />
				<ScrollToTopRoute path={PAGE_NOT_FOUND} component={PageNotFound} />
				<Redirect to={PAGE_NOT_FOUND} />
			</Switch>
		</Suspense>
	);
};
