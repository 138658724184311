import { useQuery } from 'react-query';

import { API_URL, METHODS } from 'constants/api';
import { QUERY_KEYS } from 'constants/index';
import fetchWrapper from 'utils/fetch-wrapper';

const getPendoMetadata = (isUserLoggedIn, nodeEnvironment) => {
	if (!isUserLoggedIn || nodeEnvironment === 'development') return;
	const { PENDO_SERVICE, APIM_ROOT } = API_URL;
	const _url = `${APIM_ROOT(PENDO_SERVICE)}/v1/metadata`;
	return fetchWrapper(_url, METHODS.GET);
};

export default function useRegisterPendoMetadata(isUserLoggedIn, nodeEnvironment) {
	let pendoServiceMetadata = useQuery(QUERY_KEYS.PENDO, (isUserLoggedIn, nodeEnvironment) => getPendoMetadata(isUserLoggedIn, nodeEnvironment));
	if (!isUserLoggedIn || nodeEnvironment === 'development') return;
	if (pendoServiceMetadata.isSuccess && window.pendo.accountId == undefined) {
		console.info('Initializing Pendo');
		window.pendo &&
			window.pendo.initialize({
				apiKey: '8621d39c-8c6f-413e-67b0-74762f2cca48',
				visitor: pendoServiceMetadata.data,
				account: {
					id: pendoServiceMetadata.data.clientId,
					name: pendoServiceMetadata.data.clientName
				}
			});
	}
}
