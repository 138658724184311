import { useState } from 'react';
import { UserManager } from 'paycor-oidc-client';

import { ACTIONS, MAX_POLLING_DURATION } from 'constants/index';
import config from 'config';
import { useLoggedInUserDispatchContext } from 'context/loggedin-user-context';
import useInterval from 'hooks/useInterval';

export default () => {
	const dispatch = useLoggedInUserDispatchContext();
	const [intervalCount, setIntervalCount] = useState(0);
	const [user, setUser] = useState(null);
	const isRunningInterval = !Boolean(user) && intervalCount < MAX_POLLING_DURATION;

	useInterval(
		() => {
			setIntervalCount(intervalCount + 1);
			let manager = new UserManager(config.securitySettings);
			manager.getUser().then((user) => {
				if (Boolean(user) && !user.expired) {
					setUser(user);
					dispatch({
						type: ACTIONS.LOGGED_IN_USER_LOADED,
						payload: user
					});
				}
			});
		},
		isRunningInterval ? 1000 : null
	);
};
