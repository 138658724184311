import { React } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { LoadingStatus } from '@paycor/design-system';
import { Security } from 'paycor-react-oidc';

import config from 'config';
import { ROUTE_URLS } from 'constants/index';
import { redirectToLogout, clearUser } from 'utils/authentication';
import useSetLoggedInUser from 'hooks/useSetLoggedInUser';
import { useLoggedInUserDispatchContext, useLoggedInUserStateContext } from 'context/loggedin-user-context';
import useRegisterPendoMetadata from 'hooks/useRegisterPendoMetadata';
import TermsAndConditionsModal from 'features/AppCreator/_components/Applications/TermsAndConditionsModal';
import useGetMeDetails from 'features/AppCreator/hooks/useGetMeDetails';

import logo from 'images/paycor_developer_portal.svg';
import styles from './_styles.module.scss';

const Header = () => {
	const { data: meDetails } = useGetMeDetails();

	const {
		HOST: { BASE_DOMAIN_URL, HCM_BASE_DOMAIN_URL },
		IS_PRODUCTION
	} = config;
	const { APP_CREATOR, DEV_PORTAL } = ROUTE_URLS;
	let location = useLocation();
	const dispatch = useLoggedInUserDispatchContext();
	const state = useLoggedInUserStateContext();
	const isUserLoggedIn = Boolean(state) && Boolean(state.user);

	useSetLoggedInUser();
	useRegisterPendoMetadata(isUserLoggedIn, process.env.NODE_ENV);

	const showAppcreatorLink = () => {
		if (typeof meDetails === 'undefined' || meDetails === null) return false;
		if (meDetails.isPaycor || meDetails.hasEntities) return true;
		return false;
	};

	const getReturnUrl = () => {
		return encodeURI(location.pathname);
	};

	return (
		<Security
			settings={config.securitySettings}
			onUnAuthorizedCallback={() => {}}
			onAuthErrorCallback={() => {}}
			consoleLogsEnabled={false}
			loaderComponent={<LoadingStatus />}
		>
			<TermsAndConditionsModal id="terms-and-condition-modal"></TermsAndConditionsModal>
			<header-container id="nav-header">
				<header-row>
					<left-menu-section>
						<menu-logo>
							<NavLink to="/">
								<img className="content picture picture-default" src={logo} alt="logo" title="Paycor Developer Portal" />
							</NavLink>
						</menu-logo>
					</left-menu-section>
					<right-menu-section>
						<nav className={styles['menu']}>
							<menu-horizontal>
								<ul className={styles['nav']}>
									<li className={styles['nav-item']}>
										<NavLink to={DEV_PORTAL.API_EXPLORE} className={styles['nav-link']}>
											APIs
										</NavLink>
									</li>
									<li className={styles['nav-item']}>
										<NavLink to={DEV_PORTAL.GUIDES} className={styles['nav-link']}>
											Guides
										</NavLink>
									</li>
									{isUserLoggedIn && (
										<li className={styles['nav-item']}>
											<NavLink to={DEV_PORTAL.SUPPORT} className={styles['nav-link']}>
												Support
											</NavLink>
										</li>
									)}
									{showAppcreatorLink() && isUserLoggedIn && (
										<li className={styles['nav-item']}>
											<NavLink to={APP_CREATOR.SUMMARY} className={styles['nav-link']}>
												Applications
											</NavLink>
										</li>
									)}
									{isUserLoggedIn && (
										<li className={styles['nav-item']}>
											<NavLink to={DEV_PORTAL.NEWS} className={styles['nav-link']}>
												What's New
											</NavLink>
										</li>
									)}
									<li className={styles['nav-item']}>
										{isUserLoggedIn ? (
											<a
												href="#"
												onClick={() => {
													redirectToLogout();
													clearUser(dispatch);
												}}
												className={styles['nav-link']}
											>
												Sign Out
											</a>
										) : (
											<a href={`${HCM_BASE_DOMAIN_URL}/accounts/Authentication/Signin?ReturnUrl=${BASE_DOMAIN_URL}${getReturnUrl()}`} className={styles['nav-link']}>
												Sign In
											</a>
										)}
									</li>
								</ul>
							</menu-horizontal>
						</nav>
					</right-menu-section>
				</header-row>
			</header-container>
			{IS_PRODUCTION && <production-banner>PRODUCTION ENVIRONMENT</production-banner>}
			{!IS_PRODUCTION && <sandbox-banner>SANDBOX ENVIRONMENT</sandbox-banner>}
		</Security>
	);
};

export default Header;
