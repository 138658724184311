import config from 'config';
import buildAuthToken from 'utils/build-auth-token';

const {
	APIM_SUBSCRIPTION_KEY,
	HOST: { APIM_BASE_DOMAIN_URL }
} = config;

export const API_ERROR_MESSAGE = 'Sorry, something went wrong.  Please try again later.';
export const API_LOAD_ERROR_MESSAGE = "Sorry, we can't load $token right now. Please wait a bit and try again.";

export let STANDARD_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
	pragma: 'no-cache',
	'cache-control': 'no-cache'
};

let FORM_DATA_HEADERS = {
	Accept: 'application/json, text/javascript, */*',
	pragma: 'no-cache',
	'cache-control': 'no-cache'
};

export const METHODS = {
	GET: 'get',
	POST: 'post',
	PUT: 'put',
	DELETE: 'delete',
	PATCH: 'PATCH'
};

export const HTTP_HEADERS = (type = 'standard') => {
	let _headers = type === 'form' ? FORM_DATA_HEADERS : STANDARD_HEADERS;
	_headers = Object.assign(_headers, {
		'Ocp-Apim-Subscription-Key': APIM_SUBSCRIPTION_KEY,
		authorization: `${buildAuthToken()}`
	});

	return {
		GET: {
			method: METHODS.GET,
			mode: 'cors',
			headers: _headers,
			credentials: 'include'
		},
		POST: {
			method: METHODS.POST,
			mode: 'cors',
			headers: _headers,
			credentials: 'include'
		},
		PUT: {
			method: METHODS.PUT,
			mode: 'cors',
			headers: _headers,
			credentials: 'include'
		},
		DELETE: {
			method: METHODS.DELETE,
			mode: 'cors',
			headers: _headers,
			credentials: 'include'
		},
		PATCH: {
			method: METHODS.PATCH,
			mode: 'cors',
			headers: _headers,
			credentials: 'include'
		}
	};
};

export const API_URL = {
	API: `/api`,
	ACCOUNTS: `/accounts`,
	STS: `/sts/`,
	APP_CREATOR: `/appcreator`,
	PENDO_SERVICE: `/pendoservice`,
	APIM_ROOT: (api) => {
		return process.env.NODE_ENV === 'production' ? `${APIM_BASE_DOMAIN_URL}${api}` : api;
	}
};

export const API_RESPONSE_MESSAGES = {
	ACCESS_DENIED: 'Access Denied'
};
