export const APIM_SUBSCRIPTION_KEY = '#{Project-APIm-Key}';
export const API_TIME_OUT = '#{ServerCallTimeOut}';
export const JOIN_FEATURE_FLAG = '#{JoinFeatureFlag}'.includes('true');
export const IS_PRODUCTION = '#{Developer-Portal-IsProduction}'.toUpperCase() == 'TRUE' ? true : false;

export const HOST = {
	HCM_BASE_DOMAIN_URL: '#{BaseURL-HCM}',
	APIM_BASE_DOMAIN_URL: '#{BaseURL-API}',
	PUBLIC_APIM_BASE_DOMAIN_URL: '#{paycorpublicapim_GatewayUrl_FQDN}',
	BASE_DOMAIN_URL: '#{BaseURL-DevelopersPortal}',
	SECURE_BASE_DOMAIN_URL: '#{BaseURL-Secure}',
	MARKETPLACE_URL: '#{BaseURL-Marketplace}'
};

export const securitySettings = {
	client_id: 'DevelopersPortalUI',
	redirect_uri: `${HOST.BASE_DOMAIN_URL}/oauth/silent_renew.html`,
	response_type: 'token',
	scope: 'developersportalui',
	authority: `${HOST.APIM_BASE_DOMAIN_URL}/sts/v1/common`,
	silent_redirect_uri: `${HOST.BASE_DOMAIN_URL}/oauth/silent_renew.html`,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: false,
	includeIdTokenInSilentRenew: false,
	extraQueryParams: { 'subscription-key': APIM_SUBSCRIPTION_KEY }
};
