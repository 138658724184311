import React, { createContext, useReducer, useContext } from 'react';
import { ACTIONS } from 'constants/index';

const LoggedInUserStateContext = createContext();
const LoggedInUserDispatchContext = createContext();

const initialState = { user: null };
const loggedInUserReducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.LOGGED_IN_USER_LOADED:
			return { ...state, user: action.payload };
		default:
			return state;
	}
};

const LoggedInUserProvider = (props) => {
	const [state, dispatch] = useReducer(loggedInUserReducer, initialState);
	return (
		<LoggedInUserStateContext.Provider value={{ state }}>
			<LoggedInUserDispatchContext.Provider value={{ dispatch }}>{props.children}</LoggedInUserDispatchContext.Provider>
		</LoggedInUserStateContext.Provider>
	);
};

const useLoggedInUserStateContext = () => {
	const { state } = useContext(LoggedInUserStateContext);

	return state;
};
const useLoggedInUserDispatchContext = () => {
	const { dispatch } = useContext(LoggedInUserDispatchContext);

	return dispatch;
};
export { LoggedInUserProvider, useLoggedInUserStateContext, useLoggedInUserDispatchContext, loggedInUserReducer };
