import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@paycor/pds-button';
import Modal from '@paycor/pds-modal';
import Banner from '@paycor/pds-banner';
import { Checkbox } from '@paycor/design-system/form-elements';
import TermsOfUse from 'features/Documentation/pages/terms-of-use';
import styles from './_styles.module.scss';
import useUpdateConsentDate from 'features/AppCreator/hooks/useUpdateConsentDate';
import useGetMeDetails from 'features/AppCreator/hooks/useGetMeDetails';

const TermsAndConditionsModal = () => {
	const [isChecked, setIsChecked] = useState(false);
	const [isValidationError, setIsValidationError] = useState(false);

	const [updateConsentDate, updateConsentDateInfo] = useUpdateConsentDate();
	const { isLoading, isError, isSuccess } = updateConsentDateInfo;
	const [hasAPIFailedError, setHasAPIFailedError] = useState(false);

	const { data: meDetails } = useGetMeDetails();
	const [showModal, setShowModal] = useState();

	const licenceContentRef = useRef(null);

	useEffect(() => {
		if (licenceContentRef.current) {
			licenceContentRef.current.focus();
		}
	}, [showModal]);

	useEffect(() => {
		if (typeof meDetails === 'undefined' || meDetails === null) {
			setShowModal(false);
		} else if (!meDetails.hasAcceptedTerms && meDetails.hasEntities) {
			setShowModal(true);
		} else {
			setShowModal(false);
		}
	}, [meDetails]);

	useEffect(() => {
		if (isSuccess) {
			setShowModal(false);
			meDetails.hasAcceptedTerms = true;
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			setHasAPIFailedError(true);
		}
	}, [isError]);

	const onSubmit = (isChecked) => {
		setHasAPIFailedError(false);
		if (isChecked) {
			setIsValidationError(false);
			updateConsentDate();
		} else {
			setIsValidationError(true);
		}
	};

	return (
		<Modal
			zIndex={101}
			show={showModal}
			showClose={false}
			size={Modal.Sizes.DEFAULT}
			footerStyle={Modal.Footer.DARK}
			onClose={() => {}}
			title="Paycor's Developer Portal Terms Of Use"
			body={
				<>
					<error-panel class={isValidationError ? styles['addPadding'] : styles['removePadding']}>
						{<Banner id="banner" show={isValidationError} status={Banner.Status.ERROR} message={'Accept the Terms of Use.'} />}
					</error-panel>
					<div className={styles['licence-content']} tabIndex={-1} ref={licenceContentRef}>
						<TermsOfUse></TermsOfUse>
					</div>
				</>
			}
			footer={
				<>
					<got-it-section class={styles['addPadding']}>
						<Checkbox
							id={'agreement'}
							label={'I have read and agree to the Developer Portal Terms of Use.'}
							onChange={(e) => setIsChecked(e['checked'])}
							error={isValidationError}
						/>
					</got-it-section>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							id="submit-terms-and-conditions"
							type={Button.Types.DEFAULT}
							size={Button.Sizes.DEFAULT}
							onClick={() => onSubmit(isChecked)}
							busy={isLoading}
							busyLabel="Saving"
						>
							Submit
						</Button>
					</div>
					<error-panel class={styles['addPadding']}>
						{<Banner id="banner" show={hasAPIFailedError} status={Banner.Status.ERROR} message={'Saving failed, try again.'} />}
					</error-panel>
				</>
			}
		></Modal>
	);
};

export default TermsAndConditionsModal;
