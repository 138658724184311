import { useHistory } from 'react-router-dom';
import { useLoggedInUserStateContext } from 'context/loggedin-user-context';
import { ROUTE_URLS } from 'constants/index';
import config from 'config';
import RectangleBlockRendering from './components/RectangleBlock';
import styles from './Home.module.scss';
import apiIcon from 'images/api-icon.svg';
import createAppsIcon from 'images/create-apps.svg';
import joinIcon from 'images/JoinIcon.svg';
import guidesIcon from 'images/guides-icon.svg';
import leftSqImg from 'images/landing-page-left-square-image.png';
import devePortalTxtLogo from 'images/developer_portal.svg';
import RightSqImg from 'images/landing-page-right-square-image.png';
import logo from 'images/paycor_logo_fullcolor.svg';
import useGetMeDetails from 'features/AppCreator/hooks/useGetMeDetails';

const Home = () => {
	const { data: meDetails } = useGetMeDetails();
	const { APP_CREATOR, DEV_PORTAL } = ROUTE_URLS;
	const history = useHistory();
	const state = useLoggedInUserStateContext();
	const isUserLoggedIn = Boolean(state) && Boolean(state.user);
	const { JOIN_FEATURE_FLAG } = config;

	const showAppcreatorPage = () => {
		if (typeof meDetails === 'undefined' || meDetails === null) return false;
		if (meDetails.isPaycor || meDetails.hasEntities) return true;
		return false;
	};

	const renderTags = () => {
		if (JOIN_FEATURE_FLAG) {
			if (showAppcreatorPage() && isUserLoggedIn) {
				return (
					<RectangleBlockRendering
						alt="create-App"
						icon={createAppsIcon}
						headerName="Create"
						content={'Create and manage applications \n and their access details.'}
						buttonId="btnCreateApplication"
						buttonValue="Applications"
						handleClick={() => history.push(APP_CREATOR.SUMMARY)}
					/>
				);
			} else {
				return (
					<RectangleBlockRendering
						alt="join"
						icon={joinIcon}
						headerName="Join"
						content={'Become a part of our developer \n community.'}
						buttonId="btnSignup"
						buttonValue="Sign Up"
						handleClick={() => history.push(DEV_PORTAL.JOIN_DEVELOPER_PORTAL)}
					/>
				);
			}
		}

		if (!JOIN_FEATURE_FLAG && isUserLoggedIn) {
			return (
				<RectangleBlockRendering
					alt="create-App"
					icon={createAppsIcon}
					headerName="Create"
					content={'Create and manage applications \n and their access details.'}
					buttonId="btnCreateApplication"
					buttonValue="Applications"
					handleClick={() => history.push(APP_CREATOR.SUMMARY)}
				/>
			);
		}
	};

	return (
		<main-section>
			<top-container>
				<square-tile>
					<img src={leftSqImg} alt="left tile" />
				</square-tile>

				<middle-content>
					<center-logo>
						<img className={styles['main-logo']} alt="Paycor" title="Paycor" src={logo} />
						<img className={styles['develop-portal']} alt="Developer Portal" title="Developer Portal" src={devePortalTxtLogo} />
					</center-logo>
					<h1 className={styles['text-center']}>Developer Portal provides the APIs and resources needed to build apps with Paycor.</h1>
				</middle-content>

				<square-tile>
					<img src={RightSqImg} alt="right tile" />
				</square-tile>
			</top-container>

			<bottom-container>
				<rectangle-row>
					<RectangleBlockRendering
						icon={apiIcon}
						alt="Api"
						headerName="Explore"
						content={'Looking for something? Browse \n our collection of APIs.'}
						buttonId="btnBrowseApi"
						buttonValue="Browse APIs"
						handleClick={() => history.push(DEV_PORTAL.API_EXPLORE)}
					/>

					<RectangleBlockRendering
						icon={guidesIcon}
						alt="Guides"
						headerName="Learn"
						content={'Consult our guides \n documentation to aid your \n development.'}
						buttonId="btnViewGuides"
						buttonValue="View Guides"
						handleClick={() => history.push(DEV_PORTAL.GUIDES)}
					/>

					{renderTags()}
				</rectangle-row>
			</bottom-container>
		</main-section>
	);
};

export default Home;
