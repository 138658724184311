import react from 'react';
import Button from '@paycor/pds-button';

const RectangleBlockRendering = (props) => {
	return (
		<react.Fragment>
			<rectangle-block>
				<img src={props.icon} alt={props.alt} />
				<rectangle-block-header>{props.headerName}</rectangle-block-header>
				<rectangle-block-content>{props.content}</rectangle-block-content>
				<Button id={props.buttonId} onClick={props.handleClick}>
					{props.buttonValue}
				</Button>
			</rectangle-block>
		</react.Fragment>
	);
};

export default RectangleBlockRendering;
