import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import config from 'config';
import { HTTP_HEADERS, METHODS, API_ERROR_MESSAGE } from 'constants/api';

const responseHandler = (response, shouldReturnErrorBody) => {
	let data = null;
	if (response.ok) {
		switch (response.status) {
			case 204:
				data = response.text();
				break;
			default:
				data = response.json();
		}
	} else {
		switch (response.status) {
			case 400:
			case 409:
				if (shouldReturnErrorBody) {
					let error = new Error();
					error = { ...error, response };
					throw error;
				}
				throw new Error('Bad Request');

			case 401:
			case 403:
				throw new Error('Access Denied');
			default:
				throw new Error('Could not retrieve data');
		}
	}
	return data;
};

const errorHandler = (error) => {
	if (error.name === 'AbortError') throw new Error('Request Timeout.');
	throw new Error(API_ERROR_MESSAGE);
};

const objectToFormData = (obj, rootName, ignoreList) => {
	let formData = new FormData();
	const appendFormData = (data, root) => {
		if (!ignore(root)) {
			root = root || '';
			if (data instanceof File) {
				formData.append(root, data);
			} else if (Array.isArray(data)) {
				for (var i = 0; i < data.length; i++) {
					appendFormData(data[i], root + '[' + i + ']');
				}
			} else if (typeof data === 'object' && data) {
				for (var key in data) {
					if (data.hasOwnProperty(key)) {
						if (root === '') {
							appendFormData(data[key], key);
						} else {
							appendFormData(data[key], root + '.' + key);
						}
					}
				}
			} else {
				if (data !== null && typeof data !== 'undefined') {
					formData.append(root, data);
				}
			}
		}
	};

	const ignore = (root) => {
		return (
			Array.isArray(ignoreList) &&
			ignoreList.some(function(x) {
				return x === root;
			})
		);
	};

	appendFormData(obj, rootName);
	return formData;
};

const fetchWrapper = (url, method, body = {}, type = 'standard', shouldReturnErrorBody = false, handler = responseHandler) => {
	let fetchPromise = new Promise((resolve, reject) => {
		resolve();
	});

	const { API_TIME_OUT } = config;
	const ABORT_CONTROLLER = new AbortController();
	setTimeout(() => ABORT_CONTROLLER.abort(), API_TIME_OUT);

	switch (method) {
		case METHODS.GET:
			const GET_HEADERS = Object.assign({}, HTTP_HEADERS(type).GET);
			fetchPromise = fetch(url, GET_HEADERS).then((response) => handler(response, shouldReturnErrorBody), errorHandler);
			break;
		case METHODS.POST:
			fetchPromise = fetch(
				url,
				Object.assign(HTTP_HEADERS(type).POST, {
					body: type === 'form' ? objectToFormData(body) : JSON.stringify(body)
				})
			).then((response) => handler(response, shouldReturnErrorBody), errorHandler);
			break;
		case METHODS.PUT:
			fetchPromise = fetch(
				url,
				Object.assign(HTTP_HEADERS(type).PUT, {
					body: type === 'form' ? objectToFormData(body) : JSON.stringify(body)
				})
			).then((response) => handler(response, shouldReturnErrorBody), errorHandler);
			break;
		case METHODS.DELETE:
			fetchPromise = fetch(
				url,
				Object.assign(HTTP_HEADERS(type).DELETE, {
					body: type === 'form' ? objectToFormData(body) : JSON.stringify(body)
				})
			).then((response) => handler(response, shouldReturnErrorBody), errorHandler);
			break;
		case METHODS.PATCH:
			fetchPromise = fetch(
				url,
				Object.assign(HTTP_HEADERS(type).PATCH, {
					body: type === 'form' ? objectToFormData(body) : JSON.stringify(body)
				})
			).then((response) => handler(response, shouldReturnErrorBody), errorHandler);
			break;
		default:
			break;
	}
	return fetchPromise;
};

export default fetchWrapper;
