import React, { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

const ScrollToTopRoute = (props) => {
	const { pathname } = useLocation();
	const { component: AppComponent, ...rest } = props;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<Route {...rest} component={AppComponent} />
		</>
	);
};

export default ScrollToTopRoute;
