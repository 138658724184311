export const MAX_POLLING_DURATION = 60000;
export const DOES_NOT_EXPIRY_LABEL = 'Does not expire';
export const EXPIRED_LABEL = 'EXPIRED';

export const QUERY_KEYS = {
	PARTNER_ACCOUNT: 'PARTNER_ACCOUNT',
	ME: 'ME',
	USERS: 'USERS',
	CLIENT_USERS: 'CLIENT_USERS',
	APPLICATIONS: 'APPLICATIONS',
	APPLICATION: 'APPLICATION',
	DATA_ACCESS: 'DATA_ACCESS',
	SECURITY_CONNECTIONS: 'SECURITY_CONNECTIONS',
	APPLICATION_SECRET: 'APPLICATION_SECRET',
	EVENT_SUBSCRIPTION: 'EVENT_SUBSCRIPTION',
	APPLICATION_VERSIONS: 'APPLICATION_VERSIONS',
	ACCOUNT_CLIENT_ASSOCIATIONS: 'ACCOUNT_CLIENT_ASSOCIATIONS',
	EMAIL_SUPPORT: 'EMAIL_SUPPORT',
	CONSENT_DATE: 'CONSENT_DATE',
	USER_INFO: 'USER_INFO',
	UPDATE_CLIENT_COMPANY: 'UPDATE_CLIENT_COMPANY',
	COMPANY_DETAILS: 'COMPANY_DETAILS',
	CHECK_EMAIL: 'CHECK_EMAIL',
	PENDO: 'PENDO',
	APPLICATION_ACTIVATED_CLIENTS: 'APPLICATION_ACTIVATED_CLIENTS'
};

export const ROUTE_URLS = {
	PAGE_NOT_FOUND: '/PageNotFound',
	DEV_PORTAL: {
		SUPPORT: '/get-support',
		NEWS: '/news',
		TERMS_OF_USE: '/terms-of-use',
		GUIDES: '/guides',
		EVENT_NOTIFICATION: '/event-notification',
		API_EXPLORE: '/explore',
		API_TRY: '/try',
		JOIN_DEVELOPER_PORTAL: '/join-developer-portal',
		HOME: '/'
	},
	APP_CREATOR: {
		HOME: '/applications',
		SUMMARY: '/applications/summary',
		MANAGE_COMPANY: '/applications/manage-company',
		MANAGE_APPLICATION: '/applications/manage-app/:applicationId/general',
		MANAGE_APPLICATION_DATA_ACCESS: '/applications/manage-app/:applicationId/data-access',
		MANAGE_APPLICATION_SECURITY_CONNECTIONS: '/applications/manage-app/:applicationId/security-connections',
		MANAGE_APPLICATION_EVENT_SUBSCRIPTION: '/applications/manage-app/:applicationId/event-subscription',
		ACCOUNT_SETUP_PAGE: '/applications/account-setup-page',
		FORBIDDEN: '/applications/Forbidden',
		ACCOUNT_SETUP_PAGE_SUCCESS: '/applications/account-setup-page/success'
	}
};

export const ACTIONS = {
	LOGGED_IN_USER_LOADED: 'LOGGED_IN_USER/LOADED',
	GLOBAL_BANNER_SHOW: 'GLOBAL_BANNER/SHOW'
};

export const USER_ROLES = {
	ADMINISTRATOR: 'Administrator',
	DEVELOPER: 'Developer',
	VIEW_ONLY: 'ViewOnly'
};

export const USER_ROLE_LABELS = {
	DEVELOPER: 'Developer',
	ADMINISTRATOR: 'Administrator',
	VIEW_ONLY: 'View Only'
};

export const USER_ACTION_MENU_OPTIONS = {
	EDIT_ACCOUNT_SETTINGS: 'Edit Account Settings',
	EDIT_ROLE: 'Edit Role',
	DELETE_USER: 'Delete User'
};

export const INITIAL_MODAL_STATE = {
	SHOW: false,
	TITLE: null,
	SIZE: null,
	BODY: null,
	FOOTER: null,
	BACKDROP: null,
	SHOWCLOSE: null,
	FOOTERSTYLE: null,
	ONCLOSE: null,
	CUSTOMMODALCLASS: null
};

export const USER_ROLE_OPTIONS = [
	{ ID: 'Administrator', LABEL: 'Administrator' },
	{ ID: 'Developer', LABEL: 'Developer' },
	{ ID: 'ViewOnly', LABEL: 'View Only' }
];

export const PAYCOR_USER_OPTIONS = [
	{ ID: 'Yes', LABEL: 'Yes' },
	{ ID: 'No', LABEL: 'No' }
];

export const MANAGE_APP_TABS = {
	DATA_ACCESS: 'Data Access',
	REPORTING_API: 'Reporting API',
	EVENT_SUBSCRIPTION: 'Event Subscription',
	GENERAL: 'General',
	SECURITY_CONNECTIONS: 'Security Connections'
};

export const ACCESS_SCOPE_REQUEST_STATUS = {
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	PENDING_APPROVAL: 'Pending'
};

export const USER_ACCOUNT_TYPE = {
	PARTNER: 'Partner',
	THIRDPARTYPRIVATE: 'ThirdPartyPrivate',
	TENANT: 'Tenant',
	LEGAL_ENTITY: 'LegalEntity'
};

export const USER_ACCESS_TYPE = {
	STANDARD: 0,
	REPORTING: 1
};

export const APPLICATION_TYPE_LABELS = {
	STANDARD: 'Standard Application',
	REPORTING: 'Reporting Application'
};

export const APPLICATION_TYPE = {
	STANDARD: 'Standard',
	REPORTING: 'Reporting'
};
