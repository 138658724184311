import { ACTIONS } from 'constants/index';
import { API_URL, HTTP_HEADERS } from 'constants/api';
import config from 'config';
import { UserManager } from 'paycor-oidc-client';

const { ACCOUNTS } = API_URL;
const {
	HOST: { HCM_BASE_DOMAIN_URL }
} = config;

export const redirectOnUnauthenticated = () => {
	const _return = encodeURIComponent(window.location.href);
	return (window.location.href = `${HCM_BASE_DOMAIN_URL}/Authentication/Signin?ReturnUrl=${_return}`);
};

export const redirectToLogout = () => {
	const _return = encodeURIComponent(window.location.href);
	return (window.location.href = `${ACCOUNTS}/Authentication/Logout?ReturnUrl=${_return}`);
};

export const refreshSession = () => {
	return fetch(`${ACCOUNTS}/`, HTTP_HEADERS().GET);
};

export const clearUser = (dispatch) => {
	let manager = new UserManager(config.securitySettings);
	manager.removeUser().then(() => {
		dispatch({
			type: ACTIONS.LOGGED_IN_USER_LOADED,
			payload: null
		});
	});
};

export const onAuthFailed = (e) => {
	console.error(`authFailed triggered:${e}`);
	return redirectOnUnauthenticated();
};

export const onAuthError = (e) => {
	console.error(`authError triggered:${e}`);
	return redirectOnUnauthenticated();
};
