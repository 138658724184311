import { useQuery } from 'react-query';
import { API_URL, METHODS } from 'constants/api';
import { QUERY_KEYS } from 'constants/index';
import fetchWrapper from 'utils/fetch-wrapper';
import { exponentialDelay } from 'utils/retry-functions';

const geGetMeDetails = () => {
	const { APP_CREATOR, APIM_ROOT } = API_URL;
	const _url = `${APIM_ROOT(APP_CREATOR)}/v1/me`;
	return fetchWrapper(_url, METHODS.GET);
};

export default function useGetMeDetails() {
	return useQuery([QUERY_KEYS.ME], geGetMeDetails, {
		staleTime: Infinity,
		retryDelay: exponentialDelay
	});
}
