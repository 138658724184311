export const APIM_SUBSCRIPTION_KEY = '535e973fbdb94a76b8f16213af561687';
export const API_TIME_OUT = 100000; // add nmore time for long API calls
export const JOIN_FEATURE_FLAG = true;
export const PORT = process.env.REACT_APP_DEV_PORT;
export const IS_PRODUCTION = 'false'.toUpperCase() == 'TRUE' ? true : false;

export const HOST = {
	HCM_BASE_DOMAIN_URL: '//hcm-quarterly.paycor.com',
	APIM_BASE_DOMAIN_URL: '//api-quarterly.paycor.com',
	PUBLIC_APIM_BASE_DOMAIN_URL: `//local.paycor.com:${PORT}`,
	BASE_DOMAIN_URL: `//local.paycor.com:${PORT}`,
	SECURE_BASE_DOMAIN_URL: '//secure-quarterly.paycor.com',
	MARKETPLACE_URL: 'https://marketplace-quarterly.paycor.com'
};

export const securitySettings = {
	client_id: 'DevelopersPortalUI',
	redirect_uri: `https://local.paycor.com:${PORT}/oauth/silent_renew.html`,
	response_type: 'token',
	scope: 'developersportalui',
	authority: '/sts/v1/common',
	silent_redirect_uri: `https://local.paycor.com:${PORT}/oauth/silent_renew.html`,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: false,
	includeIdTokenInSilentRenew: false,
	extraQueryParams: { 'subscription-key': APIM_SUBSCRIPTION_KEY }
};
