import { useMutation, queryCache } from 'react-query';

import { API_URL, METHODS } from 'constants/api';
import { QUERY_KEYS } from 'constants/index';
import fetchWrapper from 'utils/fetch-wrapper';

const updateConsentDate = () => {
	const { APP_CREATOR, APIM_ROOT } = API_URL;
	const _url = `${APIM_ROOT(APP_CREATOR)}/v1/AccountUser/${new Date().toISOString()}`;
	return fetchWrapper(_url, METHODS.PATCH, 'standard', true);
};

export default function useUpdateConsentDate(consentDate) {
	return useMutation(updateConsentDate, {
		onSuccess: () => {
			queryCache.invalidateQueries([QUERY_KEYS.CONSENT_DATE, consentDate]);
		}
	});
}
