import React from 'react';

import Footer from 'components/Footer';
import Header from 'components/Header';
import Routes from 'root-routes';
import { LoggedInUserProvider } from 'context/loggedin-user-context';

const App = () => {
	return (
		<>
			<LoggedInUserProvider>
				<Header />
				<main>
					<Routes />
				</main>
				<Footer />
			</LoggedInUserProvider>
		</>
	);
};

export default App;
